/** @jsx jsx */
import { useCallback, useState } from 'react';
import { jsx } from 'theme-ui';
import { ClassNames } from '@emotion/core';
import MaskedInputCore from 'react-maskedinput'
import { useField } from 'formik';

import theme from 'theme/theme';
import Text from 'components/Text/Text';


const MaskedInput =({
    mask,
    placeholder,
    name,
    controlledInput,
    passedValue,
    label,
    size = '14px',
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
}) => {
    const [initialFocus, setInitialFocus] = useState(false);

    const [field, meta, helpers] = useField(name);

    const hasError = meta.touched && meta.error;

    const { value: fieldValue } = meta;

    const { setValue, setTouched } = helpers;

    const value = controlledInput ? passedValue : fieldValue;

    const handleOnBlur = useCallback(() => {
        if (controlledInput) {
            onBlur();
            return;
        }

        if (initialFocus) {
            setTouched(true);
        }
    }, [controlledInput, setTouched, onBlur, initialFocus]);

    const handleOnFocus = useCallback(() => {
        if (controlledInput) {
            onFocus();
            return;
        }

        if (!initialFocus) {
            setInitialFocus(true);
        }
    }, [initialFocus, onFocus, controlledInput]);

    const handleOnChange = useCallback((event) => {
        const fn = controlledInput ? onChange : setValue;

        fn(event.target.value);
    }, [controlledInput, onChange, setValue])

    const renderTextMaskInput = (
        <ClassNames>
            {({ css }) => (
                <MaskedInputCore
                    mask={mask}
                    placeholder={placeholder}
                    {...field}
                    name={name}
                    value={value}
                    className={css({
                        fontFamily: theme.fonts.main,
                        fontSize: size,
                        outline: 'none',
                        borderRadius: theme.radii.medium,
                        border: controlledInput ? 'none' : (hasError && '2px solid') || '1px solid',
                        borderColor: !hasError ? theme.colors.gray['300'] : (hasError && theme.colors.red.base),
                        height: '38px',
                        color: theme.colors.gray['900'],
                        paddingLeft: '16px',
                        paddingRight: '12px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        width: '100%',
                        transition: 'all .3s cubic-bezier(0.075, 0.82, 0.165, 1)',
                        ':focus': {
                            border: controlledInput ? 'none' : '2px solid',
                            borderColor: hasError ? theme.colors.red.base : theme.colors.primary.base,
                        },
                    })}
                    aria-placeholder={placeholder}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    onChange={handleOnChange}
                />
            )}
        </ClassNames>
    );

    return controlledInput ? (
        renderTextMaskInput
    ) : (
        <div>
            <label htmlFor={field.name}>
                <Text size="body" as="p" color="gray.700" sx={{ marginBottom: '8px' }}>
                    {label}
                </Text>
            </label>
            {renderTextMaskInput}
            {meta.touched && meta.error ? (
                <Text
                    as="p"
                    size="note.label"
                    color="red.base"
                    sx={{ paddingLeft: '16px', mt: '6px', transition: 'all .45s cubic-bezier(0.075, 0.82, 0.165, 1)' }}
                >
                    {meta.error}
                </Text>
            ) : null}
        </div>
    );
}

export default MaskedInput;
