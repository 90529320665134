/** @jsx jsx */
import React, { Suspense } from 'react';
import { jsx } from 'theme-ui';

import * as allIcons from './icons';

const icons = { ...allIcons };

export type IconKeys = keyof typeof icons;

const isValidIconName = (iconName: string): iconName is IconKeys => Object.prototype.hasOwnProperty.call(icons, iconName);

type Size = 'small' | 'medium' | 'large';

interface GetIcon {
    (iconName: string):
        | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
        | React.LazyExoticComponent<React.FunctionComponent<React.SVGProps<SVGSVGElement>>>;
}

const getIcon = (iconName: string) => {
    if (isValidIconName(iconName)) {
        return icons[iconName];
    }

    // eslint-disable-next-[line
    console.error(`<Icon />: ${iconName} not found`);

    return icons.fallback;
};

interface IconProps {
    icon: string | IconKeys;
    height?: string | string[];
    width?: string | string[];
    size?: Size;
    color?: string;
}

const Icon: React.FC<IconProps> = ({ icon, height = 'auto', width = '10px', size = 'small', ...rest }: IconProps) => {
    const IconComponent = getIcon(icon);

    const sizes = {
        small: '8px',
        medium: '12px',
        large: '24px',
    };

    const actualWidth = width || sizes[size];

    return (
        <Suspense fallback={() => <div {...rest} />}>
            <IconComponent
                sx={{
                    flexShrink: 0,
                    height,
                    verticalAlign: 'top',
                    width: actualWidth,
                }}
                {...rest}
            />
        </Suspense>
    );
};

export default Icon;
