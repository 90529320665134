/** @jsx jsx */
import { jsx } from 'theme-ui';

import AnimatedLogoLoading from 'components/AnimatedLogoLoading/AnimatedLogoLoading';

interface LoaderProps {
    className?: string;
}

const Loader: React.FC<LoaderProps> = ({ className, ...rest }) => {
    return (
        <div
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', bg: 'white', width: '100vw', height: '100vh' }}
            {...rest}
        >
            <AnimatedLogoLoading />
        </div>
    );
};

export default Loader;
