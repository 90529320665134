/** @jsx jsx */
import { lazy } from 'react';
import { jsx } from 'theme-ui';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';

import Login from './views/Login/Login';
import { allowedApplicationTypes } from 'constants/constants';
import { setIsRefinance } from 'actions/application';

const SiteDashboard = lazy(() => import('./SiteDashboard'));

const App = () => {
    const dispatch = useDispatch();

    return (
        <Switch>
            <Route path="/dashboard" component={SiteDashboard} />
            <Route
                exact
                path="/start"
                component={({ location: { search } }) => {
                    const [, type] = search.trim().split('=');

                    if (allowedApplicationTypes.includes(type)) {
                        dispatch(setIsRefinance(type === 'refinance'));
                    }
                    return <Redirect to="/" />;
                }}
            />
            <Route path="/" component={SiteDashboard} />
        </Switch>
    );
};

export default App;
