/** @jsx jsx */
import { jsx } from 'theme-ui';
import cx from 'classnames';

import styles from './AnimatedLogoLoading.module.css';

const AnimatedLogoLoading = () => {
    return (
        <svg width="157" height="145" viewBox="0 0 157 145" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1" fill="white">
                <rect x="12.1858" y="24.0182" width="120.982" height="120.982" rx="2.95077"/>
            </mask>
            <rect x="12.1858" y="24.0182" width="120.982" height="120.982" rx="2.95077" stroke="#B0E5AC" strokeWidth="11.8031" mask="url(#path-1-inside-1)" className={styles.rectangle}/>
            <g className={styles.tick}>
                <path d="M77.9485 62.3091C82.562 58.1535 89.6177 58.3235 94.0255 62.6967C98.4334 67.0698 98.6593 74.1239 94.5403 78.7702L51.5737 127.236C45.728 133.83 35.5421 134.157 29.2864 127.95C23.0308 121.744 23.2764 111.556 29.8238 105.658L77.9485 62.3091Z" fill="url(#paint0_linear)"/>
                <path d="M8.91496 90.1158C5.61349 83.6506 7.98763 75.7322 14.3016 72.15C20.5655 68.5962 28.5147 70.5574 32.4069 76.6169L52.9835 108.652C57.7738 116.11 55.3188 126.061 47.6094 130.434C39.9581 134.775 30.2316 131.859 26.2308 124.025L8.91496 90.1158Z" fill="url(#paint1_linear)"/>
                <circle cx="107.412" cy="49.9969" r="10.6966" transform="rotate(-44.5684 107.412 49.9969)" fill="#86D780" className={cx(styles.circle, styles.circle1)}/>
                <circle cx="127.326" cy="29.8638" r="8.85232" transform="rotate(-44.5684 127.326 29.8638)" fill="#86D780" className={cx(styles.circle, styles.circle2)}/>
                <circle cx="146.347" cy="11.1495" r="7.37693" transform="rotate(-44.5684 146.347 11.1495)" fill="#86D780" className={cx(styles.circle, styles.circle3)}/>
            </g>
            <defs>
            <linearGradient id="paint0_linear" x1="94.0255" y1="62.6967" x2="38.0054" y2="118.717" gradientUnits="userSpaceOnUse">
            <stop stopColor="#86D780"/>
            <stop offset="1" stopColor="#139389"/>
            </linearGradient>
            <linearGradient id="paint1_linear" x1="14.5607" y1="72.003" x2="47.6869" y2="130.391" gradientUnits="userSpaceOnUse">
            <stop stopColor="#007882"/>
            <stop offset="1" stopColor="#22A98F"/>
            </linearGradient>
            </defs>
        </svg>

    )
}

export default AnimatedLogoLoading;
