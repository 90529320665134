const initialState = {
    currentStep: 1,
    applicationCompleted: false,
    isJointApplication: false,
    isRefinancing: false,
    disableAddressAutoComplete: false,
    totalMonthlyExpenses: {},
    showTotalMonthlyExpenses: true,
    startApplicationAfterLogin: false,
    values: {},
    steps: {
        1: {
            id: 1,
            title: 'Personal Details',
            completed: false,
            disabled: true,
        },
        2: {
            id: 2,
            title: 'Employment',
            completed: false,
            disabled: false,
        },
        3: {
            id: 3,
            title: 'Income',
            completed: false,
            disabled: false,
        },
        4: {
            id: 4,
            title: 'Real Estate',
            completed: false,
            disabled: false,
        },
        5: {
            id: 5,
            title: 'Other Assets',
            completed: false,
            disabled: false,
        },
        6: {
            id: 6,
            title: 'Other Liabilities',
            completed: false,
            disabled: false,
        },
        7: {
            id: 7,
            title: 'Expenses',
            completed: false,
            disabled: false,
        },
    }
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case 'STEP_COMPLETED':
            return {
                ...state,
                values: {
                    ...state.values,
                    ...action.values
                },
                steps: {
                    ...state.steps,
                    [action.stepId]: {
                        ...((state.steps[action.stepId] && state.steps[action.stepId]) || {}),
                        completed: true,
                        data: action.data,
                    }
                }
            };

        case 'GOTO_STEP':
            return {
                ...state,
                currentStep: action.stepId,
            };

        case 'NEXT_STEP':
            return {
                ...state,
                currentStep: state.currentStep + 1,
            };

        case 'PREVIOUS_STEP':
            return {
                ...state,
                currentStep: state.currentStep - 1,
            };

        case 'MONTHLY_EXPENSE': 
            return {
                ...state,
                totalMonthlyExpenses: {
                    ...state.totalMonthlyExpenses,
                    [action.name]: action.value,
                }
            }
    
        case 'LOAD_APPLICATION': 
            return {
                ...state,
                ...action.application,
            }

        case 'SHOW_TOTAL_MONTHLY_EXPENSES':
            return {
                ...state,
                showTotalMonthlyExpenses: action.showTotalMonthlyExpenses,
            };
        
        case 'SET_IS_REFINANCE':
            return {
                ...state,
                isRefinancing: action.status,
                startApplicationAfterLogin: true,
            };
        
        default:
            return state;
    }
}
