/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useDispatch, useSelector } from 'react-redux';

import Alert from 'components/Alert/Alert';

const GlobalNotifications = () => {
    const notifications = useSelector(({ globalNotification }) => globalNotification);
    const dispatch = useDispatch();

    console.log(`notifications...`);
    console.log(notifications);

    return (
        <div sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '0',
            left: '50%', 
            width: '100%',
            transform: 'translateX(-50%)',
        }}>
            {(notifications || []).map((notification) => (
                <Alert
                    key={`notification_${notification.id}`}
                    show
                    showCloseButton
                    type={notification.type}
                    secondsToLive={notification.duration}
                    message={notification.message}
                    onClose={() => {
                        console.log('clicked...');
                        dispatch({ type: 'GLOBAL_NOTIFICATION_REMOVE', id: notification.id });
                    }}
                />
            ))}
        </div>
    );
}

export default GlobalNotifications;
