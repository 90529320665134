import { all, put, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import { uploadInProgress } from 'actions/upload';



function* fileUpload (action) {
    const { file, dispatcher } = action;
    const data = new FormData();

    const onUploadProgress = (progressEvent) => {
        console.log(progressEvent);
        const progressPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log(`progressPercent: ${progressPercent}`)
        dispatcher(uploadInProgress(file.name, progressPercent));
    }

    data.append('name', file.name)
    data.append('file', file);

    const response = yield api.post('/documents/upload', data,  {onUploadProgress});

    yield console.log(file);
    yield console.log(response);
}

export default function* fileUploadSaga() {
    yield all([takeEvery('UPLOAD_REQUESTED', fileUpload)]);
}
