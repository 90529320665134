const initialState = {
    firstName: null,
    currentForm: 'login',
    isAuthenticated: null,
    verificationMobileNumber: null,
    showVerificationForm: false,
    verificationInProgress: false,
    failedVerificationAttempts: 0,
    loginInProgress: false,
    registerInProgress: false,
    loginErrorMessage: '',
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case 'LOAD_USER_COMPLETED':
            return {
                ...state,
                firstName: action.firstName,
                isAuthenticated: true,
            };

        case 'LOGIN_IN_PROGRESS': 
            return {
                ...state,
                loginInProgress: action.status,
            };

        case 'VERIFICATION_IN_PROGRESS': 
            return {
                ...state,
                verificationInProgress: action.status,
            };

        case 'REGISTER_USER_IN_PROGRESS': 
            return {
                ...state,
                registerInProgress: action.status,
            };

        case 'SET_VERIFICATION_MOBILE_NUMBER': 
            return {
                ...state,
                verificationMobileNumber: action.mobileNumber,
            };
        
        case 'FAILED_VERIFICATION_ATTEMPT': 
            return {
                ...state,
                failedVerificationAttempts: state.failedVerificationAttempts + 1,
            };

        case 'SET_CURRENT_FORM': 
            return {
                ...state,
                currentForm: action.formName,
            };

        case 'LOGIN_ERROR': 
            return {
                ...state,
                loginErrorMessage: action.errorMsg,
            };

        case 'CLEAR_LOGIN_ERROR': 
            return {
                ...state,
                loginErrorMessage: '',
            };

        case 'LOGOUT_COMPLETED':
            return {
                ...initialState,
                isAuthenticated: false,
            };

        default:
            return state;
    }
}
