const getId = ((count = 0) => () => {
    count += 1;
    return count;
})();

export default function globalNotification(state = [], action) {
    switch (action.type) {
        case 'GLOBAL_NOTIFICATION_ADD':
            console.log(`in here...`);
            return [...state, { ...action.notification, id: getId() }];

        case 'GLOBAL_NOTIFICATION_REMOVE':
            return state.filter((notification) => notification.id !== action.id);

        default:
            return state;
    }
}
