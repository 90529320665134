import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import rootSaga from 'sagas/rootSaga';

import createRootReducer from './rootReducer';

export const history = createBrowserHistory();

const rootReducer = createRootReducer(history);

const persistConfig = {
    key: 'root',
    blacklist: ['user', 'globalNotification'],
    storage,
  }
   
const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function configureStore(initialState = {}) {

    const sagaMiddleware = createSagaMiddleware({
        onError: (error) => {
            console.log(error);
        },
    });

    const store = createStore(
        persistedReducer,
        initialState,
        composeWithDevTools(
            applyMiddleware(
                routerMiddleware(history),
                sagaMiddleware,
            ),
        ),
    );

    sagaMiddleware.run(rootSaga, store.dispatch);

    if ((module).hot) {
        (module).hot.accept('./rootReducer', () => {
            store.replaceReducer(require('./rootReducer').default);
        });
    }

    const persistor = persistStore(store)

    return {
        store,
        persistor,
    }
}
