import { put } from 'redux-saga/effects';

import storage from 'utils/storage';
import { loadApplication } from 'actions/application';

import { APP_VERSION } from 'constants/constants';

export default function* loadState() {
    const state = (yield storage.get('appState', APP_VERSION)) || [];
    yield put(loadApplication(state.applicaton));
}
