
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History, LocationState } from 'history';

import user from './user';
import application from './application';
import upload from './upload';
import globalNotification from './globalNotification';

const rootReducer = (history: History<LocationState>) =>
    combineReducers({
        user,
        application,
        upload,
        globalNotification,
        router: connectRouter(history),
    });

export default rootReducer;
