export type ColorString =
    | 'black'
    | 'gray.100'
    | 'gray.200'
    | 'gray.300'
    | 'gray.400'
    | 'gray.500'
    | 'gray.600'
    | 'gray.700'
    | 'gray.800'
    | 'gray.900'
    | 'primary.base'
    | 'primary.dark'
    | 'primary.darker'
    | 'secondary.base'
    | 'secondary.dark'
    | 'secondary.darker'
    | 'green.light'
    | 'green.base'
    | 'green.dark'
    | 'red.light'
    | 'red.base'
    | 'red.dark'
    | 'white';

export interface NumericScale {
    '100': string;
    '200': string;
    '300': string;
    '400': string;
    '500': string;
    '600': string;
    '700': string;
    '800': string;
    '900': string;
}

export interface NameScale {
    light?: string;
    base: string;
    dark: string;
    darker?: string;
}

export interface Colors {
    black: string;
    gray: NumericScale;
    primary: NameScale;
    secondary: NameScale;
    green: NameScale;
    red: NameScale;
    white: string;
}

const colors: Colors = {
    black: '#000000',
    gray: {
        100: '#F2F2F3',
        200: '#DCDCDF',
        300: '#C6C6CB',
        400: '#A5A7AF',
        500: '#828592',
        600: '#676B78',
        700: '#4E525D',
        800: '#363A41',
        900: '#202327',
    },
    primary: {
        base: '#1EAB90',
        dark: '#198E74',
        darker: '#106248',
    },
    secondary: {
        base: '#097881',
        dark: '#08636C',
        darker: '#07454C',
    },
    green: {
        light: '#B5E3C4',
        base: '#0AA13B',
        dark: '#065218',
    },
    red: {
        light: '#F3B3BD',
        base: '#D70022',
        dark: '#87000D',
    },
    white: '#ffffff',
};

export default colors;
