/** @jsx jsx */
import { useEffect } from 'react';
import { jsx } from 'theme-ui';
import { object, string } from 'yup';

import Text from 'components/Text/Text';
import SplitField from 'components/Form/SplitField';

export const  loginFormSchema = object().shape({
    mobileNumber: string()
        .test('len', 'Please enter a valid mobile number.', (value) => value?.trim().replace(/[-_]/g,'')?.length === 10)
        .required("Sorry, mobile can't be blank."),
})

const LoginForm = ({ errorMessage, resetForm }) => {

    useEffect(() => {
        resetForm();
    }, [resetForm])

    return (
        <div sx={{
            mt: '32px'
        }}>       
                <div sx={{mb: '32px'}}>
                    <Text size="h3" as="p" color="gray.800" sx={{mb:'8px'}}>Log In</Text>
                    <Text size="body.small" color="gray.500">We'll send you a <Text size="label.small">one-time password</Text> on this mobile number. </Text>
                </div>
                <SplitField smallLabel name="mobileNumber" label="Enter Mobile Number" errorMessage={errorMessage} />
        </div>
    )
}

export default LoginForm;
