/** @jsx jsx */
import { useState, useEffect } from 'react';
import { jsx } from 'theme-ui';
import { useField } from 'formik';

import Text from 'components/Text/Text';

const Field = ({ label, name, width, forceDefaultValue = false, defaultValue='', smallLabel = false,  ...props }) => {
    const [forcedDefaultValue, setForcedDefaultValue] = useState(false);
    const [field, meta, helpers] = useField(name);
    const { setValue } = helpers;
    const { value } = meta;
    const hasError = meta.touched && meta.error;

    useEffect(() => {
        if (forceDefaultValue && !value && !forcedDefaultValue) {
            setValue(defaultValue);
            setForcedDefaultValue(true);
        }
    }, [forceDefaultValue, setValue, forcedDefaultValue, value, defaultValue]);

    return (
        <div sx={{
            width: width || '100%',
            mb: smallLabel ? '10px' : 'initial',
        }}>
            <label htmlFor={field.name}>
                <Text size="body" as="p" color="gray.700" sx={{ marginBottom: '8px', textTransform: 'initial', ...((smallLabel && {fontSize: '14px', fontWeight: 'medium', color: 'gray.900'}) || {}) }}>
                    {label}
                </Text>
            </label>
            <input
                type="text"
                {...field}
                {...props}
                sx={{
                    fontFamily: 'main',
                    fontSize: smallLabel ? 'body' : 'body.small',
                    outline: 'none',
                    borderRadius: 'medium',
                    border: hasError ? '2px' : '1px',
                    borderColor: hasError ? 'red.base' : 'gray.300',
                    height: '38px',
                    color: 'gray.900',
                    px: '16px',
                    py: '8px',
                    width: '100%',
                    transition: 'all .3s cubic-bezier(0.075, 0.82, 0.165, 1)',
                    ':focus': {
                        border: '2px',
                        borderColor: hasError ? 'red.base' : 'primary.base',
                    },
                }}
            />
            {meta.touched && meta.error ? (
                <Text as="p" size="note.label" color="red.base" sx={{ paddingLeft: '16px', mt: '6px', transition: 'all .45s cubic-bezier(0.075, 0.82, 0.165, 1)', }}>
                    {meta.error}
                </Text>
            ) : null}
        </div>
    );
};

export default Field;
