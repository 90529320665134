/** @jsx jsx */
import { useEffect } from 'react';
import { jsx } from 'theme-ui';
import * as Yup from 'yup';

import Text from 'components/Text/Text';
import Digits from 'components/Form/Digits';

export const  verificationFormSchema = Yup.object().shape({
    verificationCode: Yup
        .string()
        .required('Please enter complete verification code.')
        .test('len', 'Please enter complete verification code', (value) => value?.trim()?.length === 6),
});

const obfuscateMobileNumber = (mobileNumber) => {
    if (!mobileNumber) {
        return;
    }

    return `+61 ***${mobileNumber.charAt(3)} *** ${mobileNumber.substr(-3)}`;
}

const VerificationForm = ({ mobileNumber, resetForm, failedVerificationAttempts }) => {

    useEffect(() => {
        resetForm();
    },[resetForm, failedVerificationAttempts]);

    return (
        <div sx={{
            mt: '32px'
        }}>       
                <div sx={{mb: '32px'}}>
                    <Text size="h3" as="p" color="gray.800" sx={{mb:'8px'}}>Verify</Text>
                    <Text size="body.small" color="gray.600">Enter the 6-digit verification code we sent to the mobile number <Text size="label.small">{obfuscateMobileNumber(mobileNumber)}</Text>.</Text>
                </div>
                <Digits name="verificationCode" />
        </div>
    )
}

export default VerificationForm;
