import localforage from 'localforage';
import * as memoryDriver from 'localforage-driver-memory';
import * as sessionStorageDriver from 'localforage-driver-session-storage';

localforage.defineDriver(memoryDriver);
localforage.defineDriver(sessionStorageDriver);

const storage = (storeInstance) => ({
    isUsingLocalStorage() {
        return storeInstance.driver() === localforage.LOCALSTORAGE;
    },

    async get(key, version = 1) {
        try {
            const item = await storeInstance.getItem(key);

            if (item === null) {
                return null;
            }

            // value is outdated
            if ((item.expires && new Date().getTime() >= item.expires) || (item.version && item.version !== version)) {
                await storeInstance.removeItem(key);
                return null;
            }

            return item.value;
        } catch (err) {
            console.log(err);
            return null;
        }
    },

    async set(key, value, duration = null, version = 1) {
        const result = await storeInstance.setItem(key, {
            value,
            expires: duration && new Date().getTime() + duration * 1000,
            version,
        });
        return result;
    },

    async remove(key) {
        const result = await storeInstance.removeItem(key);
        return result;
    },
});

export default storage(
    localforage.createInstance({
        name: 'FinTikSession',
        version: 1.0,
        // eslint-disable-next-line no-underscore-dangle
        driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE, memoryDriver._driver],
    }),
);

export const sessionStorage = storage(
    localforage.createInstance({
        name: 'FinTikSession',
        version: 1.0,
        // eslint-disable-next-line no-underscore-dangle
        driver: [sessionStorageDriver._driver, memoryDriver._driver],
    }),
);
