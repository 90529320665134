/** @jsx jsx */
import { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { jsx } from 'theme-ui';

export const buttonStyle = {
    border: 0,
    borderRadius: 'large',
    color: 'inherit',
    cursor: 'pointer',
    display: 'inline-block',
    flexShrink: 0,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    margin: 0,
    outline: 0,
    px: 6,
    py: 2,
    position: 'relative',
    WebkitTapHighlightColor: 'transparent',
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'inherit',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    transition: 'all .45s cubic-bezier(0.075, 0.82, 0.165, 1)',
    ':disabled': {
        opacity: 0.5,
        cursor: 'default',
    },
    '::-moz-focus-inner': {
        border: 0,
    },
};

const BaseButton = forwardRef(({ children = null, to, disabled = false, type = 'button', ...rest }, ref) => {
    const ButtonElement = to ? NavLink : 'button';

    const sharedProps = {
        sx: buttonStyle,
        ref,
        ...rest,
    };

    const buttonProps = { ...sharedProps, type, disabled };

    const linkProps = { ...sharedProps, to };

    const elementProps = to ? linkProps : buttonProps;

    return <ButtonElement {...elementProps}>{children}</ButtonElement>;
});

export default BaseButton;
