export const refreshUser = () => {
    return { type: 'REFRESH_USER' };
}

export const loadUser = ({ type: 'LOAD_USER'});

export const logout = ({ type: 'LOGOUT'});

export const loadUserCompleted = (firstName) => ({
    type: 'LOAD_USER_COMPLETED',
    firstName,
    isAuthenticated: true,
})

export const register = (user) => {
    return { type: 'REGISTER_USER', user };
}

export const registerInProgress = (status) => {
    return { type: 'REGISTER_USER_IN_PROGRESS', status };
}

export const login = (data) => {
    return { type: 'LOGIN', data };
}

export const resendVerificationCode = ({
    type: 'RESEND_VERIFICATION_CODE',
    isResend: true,
})

export const loginInProgress = (status) => {
    return { type: 'LOGIN_IN_PROGRESS', status };
}

export const showVerificationForm = (status) => {
    return { type: 'SHOW_VERIFICATION_FORM', status };
}

export const setCurrentForm = (formName) => {
    return { type: 'SET_CURRENT_FORM', formName };
}

export const setVerificationMobileNumber = (mobileNumber) => {
    return { type: 'SET_VERIFICATION_MOBILE_NUMBER', mobileNumber };
}

export const verify = (verificationCode) => {
    return { type: 'VERIFY', verificationCode };
}

export const verificationInProgress = (status) => {
    return { type: 'VERIFICATION_IN_PROGRESS', status };
}

export const setFailedVerificationAttempt = () => {
    return { type: 'FAILED_VERIFICATION_ATTEMPT' };
}

export const loginError = (errorMsg) =>  {
    return { type: 'LOGIN_ERROR', errorMsg };
}

export const clearLoginError = () =>  {
    return { type: 'CLEAR_LOGIN_ERROR' };
}
