/** @jsx jsx */
import { jsx } from 'theme-ui';

import GlobalNotifications from 'components/GlobalNotification/GlobalNotification';

const Layout = ({
    children
}) => (
        <div
            sx={{
                position: 'relative',
                minWidth: '100vw',
                minHeight: '100%',
            }}
        >
           {children}
           <GlobalNotifications />
        </div>
    );

export default Layout;
