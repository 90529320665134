/** @jsx jsx */
import { useState, useCallback } from 'react';
import { jsx } from 'theme-ui';
import { useField } from 'formik';

import Text from 'components/Text/Text';
import MaskedInput from 'components/MaskedInput/MaskedInput';

const SplitField = ({ label, splitText = '+61', type = 'mobile', errorMessage, smallLabel = false, ...props }) => {
    const [, meta, helpers] = useField(props.name);

    const { setValue, setTouched } = helpers;

    const [focused, setFocus] = useState(false);
    const [initialFocus, setInitialFocus] = useState(false);

    const handleOnBlur = useCallback(() => {
        if (initialFocus) {
            setTouched(true);
        }
        setFocus(false);
    }, [setTouched, initialFocus]);

    const handleOnFocus = useCallback(() => {
        if (!initialFocus) {
            setInitialFocus(true);
        }

        setFocus(true);
    }, [initialFocus]);

    const handleOnChange = useCallback((value) => setValue(value), [setValue]);

    const { value } = meta;

    const hasError = (meta.touched && meta.error) || errorMessage;

    const config = {
        mobile: {
            pattern: '1111-111-111',
            placeholder: '0000-000-000'
        },
        phone: {
            pattern: '11-1111-1111',
            placeholder: '00-0000-0000',
        }
    }

    return (
        <div>
            <label>
                <Text size="body" as="p" color="gray.700" sx={{ marginBottom: '8px', ...((smallLabel && {fontSize: '14px', fontWeight: 'medium', color: 'gray.900'}) || {}) }}>
                    {label}
                </Text>
            </label>
            <div
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    height: '38px',
                }}
            >
                <Text size={smallLabel ? 'body' : 'body.small'} sx={{ pl: '16px', pr: '16px', borderRight: '1px', borderRightColor: 'gray.300' }}>
                    {splitText}
                </Text>
                <MaskedInput
                    controlledInput
                    mask={config[type]?.pattern}
                    placeholder={config[type]?.placeholder}
                    onChange={handleOnChange}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    passedValue={value}
                    size={smallLabel ? '16px' : '14px'}
                    sx={{
                        fontFamily: 'main',
                        fontSize: smallLabel ? 'body' : 'body.small',
                        color: 'gray.900',
                        px: '12px',
                        py: '8px',
                        border: 'none',
                        outline: 'none',
                        ':focus + div': {
                            border: '2px',
                            borderColor: hasError ? 'red.base' : 'primary.base',
                        },
                    }}
                />
                <div
                    sx={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: 'medium',
                        border: (focused || hasError) ? '2px' : '1px',
                        borderColor: focused && !hasError ? 'primary.base' : (hasError && 'red.base') || 'gray.300',
                        pointerEvents: 'none',
                    }}
                />
            </div>
            {hasError ? (
                <Text as="p" size="note.label" color="red.base" sx={{ paddingLeft: '16px', mt: '6px' }}>
                    {meta.error || errorMessage}
                </Text>
            ) : null}
        </div>
    );
};

export default SplitField;
