/** @jsx jsx */
import { jsx } from 'theme-ui';
import * as Yup from 'yup';
import { useEffect } from 'react';

import Text from 'components/Text/Text';
import Field from 'components/Form/Field';
import SplitField from 'components/Form/SplitField';

import { fullNameRegex } from 'constants/constants';

export const  registrationFormSchema = Yup.object().shape({
    fullName: Yup.string().trim().matches(fullNameRegex, 'Please enter a valid full name.').required("Sorry, full name can't be blank."),
    email: Yup.string().email('Please enter a valid email address.').required("Sorry, email can't be blank."),
    mobile: Yup.string()
        .test('len', 'Please enter a valid mobile number.', (value) => value?.trim().replace(/[-_]/g,'')?.length === 10)
        .required("Sorry, mobile can't be blank."),
})

const RegistrationForm = ({ resetForm, currentForm }) =>  {

    useEffect(() => {
        resetForm();
    }, [resetForm, currentForm])

    return (
        <div sx={{
            mt: '16px',
        }}>       
                <div sx={{mb: '32px'}}>
                    <Text size="h3" as="p" color="gray.800" sx={{mb:'8px'}}>Secure your information</Text>
                    <Text size="body.small" color="gray.600">Create an account to secure your information.</Text>
                </div>
                <Field name="fullName" label="Full name" autoComplete="new-off" smallLabel />
                <Field name="email" label="Email" autoComplete="new-off" smallLabel />
                <SplitField name="mobile" label="Mobile" autoComplete="new-off" smallLabel />
                <Text as="p" size="body.small" color="gray.400" sx={{ fontSize: '12px', textAlign: 'center', mt: '16px'}}>
                    By providing my name, email & mobile number, I hereby agree and accept the{' '}
                    <Text size="label.small" sx={{ fontSize: '12px', textDecoration: 'underline', color: 'gray.600' }}>
                        Terms of Service
                    </Text>{' '}
                    and{' '}
                    <Text size="label.small" sx={{ fontSize: '12px', textDecoration: 'underline', color: 'gray.600' }}>
                        Privacy Policy
                    </Text>{' '}
                    in use of this app.
                </Text>
        </div>
    )
}

export default RegistrationForm;
