import { all, fork } from 'redux-saga/effects';
import loadState from './loadState';
import fileUpload from './fileUploadSaga';
import userSaga from './userSaga';
import initialiseSaga from './initialiseSaga';

export default function* rootSaga() {
    yield all([
        fork(initialiseSaga),
        fork(loadState),
        fork(fileUpload),
        fork(userSaga),
    ]);
}
