import { all, put, takeLatest, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import api from 'utils/api';

import {
    loginInProgress,
    verificationInProgress,
    registerInProgress,
    loginError,
    loadUserCompleted,
    clearLoginError,
    setCurrentForm,
    setVerificationMobileNumber,
    setFailedVerificationAttempt,
} from 'actions/user';

import { addNotification } from 'actions/globalNotification';

const isErrorResponse = (response) => !response.success && response.error && response.message;

const getMobileNumber = (state) => state.user.verificationMobileNumber;
const getStartApplicationAfterLogin = (state) => state.application.startApplicationAfterLogin;

function* register(action) {
    const { user } = action;

    yield put(registerInProgress(true));

    const response = yield api.post('/register', user);

    if (isErrorResponse(response) && response.error === 'missing_parameter') {
        yield put(registerInProgress(false));
        return;
    }

    if (isErrorResponse(response) && response.error === 'account_create_error') {
        yield put(
            addNotification({
                message: response.message,
            }),
        );
        yield put(registerInProgress(false));
        return;
    }

    yield put(setVerificationMobileNumber(response?.mobileNumber));
    yield put(setCurrentForm('verification'));
    yield put(registerInProgress(false));
}

function* login(action) {
    const { data, isResend } = action;

    yield put(loginInProgress(true));

    const mobileNumber = yield select(getMobileNumber);

    const normalisedMobileNumber = `${mobileNumber?.slice(0, 4)}-${mobileNumber?.slice(4, 7)}-${mobileNumber?.slice(7)}`;

    const response = yield api.post('/login', (isResend && { mobileNumber: normalisedMobileNumber }) || data);

    if (isErrorResponse(response) && response.error === 'missing_parameter') {
        yield put(loginError(response.message['mobileNumber']));
        yield put(loginInProgress(false));
        return;
    }

    if (isErrorResponse(response) && response.error === 'login_error') {
        yield put(
            addNotification({
                type: 'error',
                message: response.message,
            }),
        );
        yield put(loginInProgress(false));
        return;
    }

    if (response?.mobileNumber) {
        yield put(clearLoginError());
        yield put(setVerificationMobileNumber(response?.mobileNumber));
        yield put(loginInProgress(false));

        isResend
            ? yield put(
                  addNotification({
                      message: 'One-Time password sent!',
                  }),
              )
            : yield put(setCurrentForm('verification'));

        return;
    }

    yield put(loginInProgress(false));

    return null;
}

function* verify(action) {
    const { verificationCode } = action;
    const mobileNumber = yield select(getMobileNumber);

    yield put(verificationInProgress(true));

    const response = yield api.post('/verify', {
        verificationCode,
        mobileNumber,
    });

    if (isErrorResponse(response) && response.error === 'verification_error') {
        yield put(verificationInProgress(false));
        yield put(
            addNotification({
                type: 'error',
                message: response.message,
            }),
        );
        yield put(setFailedVerificationAttempt());
        return;
    }

    if (response?.success) {
        yield put(setCurrentForm('login'));

        const startApplicationAfterLogin = yield select(getStartApplicationAfterLogin);

        if (startApplicationAfterLogin) {
            yield put(replace('/dashboard/application'));
            return;
        }
        yield put(replace('/dashboard'));
    }
}

function* loadUser(action) {
    try {
        const response = yield api.get('/user/me');

        if (response?.firstName || true) {
            yield put(loadUserCompleted(response?.firstName));
        }
    } catch (e) {
        // yield put(replace('/'));
        return;
    }
}

function* logout(action) {
    try {
        yield api.post('/logout');
        yield put(replace('/'));
    } catch (e) {
        yield put(replace('/'));
        return;
    }
}

export default function* userSaga() {
    yield all([
        takeLatest('REGISTER_USER', register),
        takeLatest(['LOGIN', 'RESEND_VERIFICATION_CODE'], login),
        takeLatest('VERIFY', verify),
        takeLatest('LOAD_USER', loadUser),
        takeLatest('LOGOUT', logout),
    ]);
}
