import colors from './colors';

const TABLET_MIN = 480;
const DESKTOP_MIN = 980;
const DESKTOP_LARGE_MIN = 1500;

export const getBreakPoints = (): string[] => [TABLET_MIN, DESKTOP_MIN, DESKTOP_LARGE_MIN].map((val) => `${val}px`);

const breakpoints: string[] & {
    tablet?: string;
    desktop?: string;
    desktopLarge?: string;
} = getBreakPoints();

[breakpoints.tablet, breakpoints.desktop, breakpoints.desktopLarge] = breakpoints;

const space = {
    px: '1px',
    '0': '0',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '8': '2rem',
    '10': '2.5rem',
    '12': '3rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '32': '8rem',
    '40': '10rem',
    '48': '12rem',
    '56': '14rem',
    '64': '16rem',
};

const theme = {
    shadows: {
        sideNav: '2px 0px 20px rgba(0, 0, 0, 0.15)',
        panel: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        button: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        modal: '0 1px 50px rgba(52,59,64,.3), 0 1px 4px rgba(0,0,0,.2)',
    },
    colors,
    space,
    borderWidths: {
        ...space,
    },
    sizes: {
        ...space,
        sideBar: '280px',
    },
    fonts: {
        main: 'Roboto, Arial, Helvetica, sans-serif',
    },
    fontSizes: {
        note: '12px',
        small: '14px',
        body: '16px',
        h4: '18px',
        h3: '24px',
        h2: '30px',
        h1: '36px',
    },
    fontWeights: {
        normal: 400,
        medium: 500,
        bold: 700,
    },
    lineHeights: {
        base: 1.5,
    },
    radii: {
        base: '4px',
        medium: '6px',
        large: '8px',
    },
    borders: {
        none: 0,
        '1px': '1px solid',
        '2px': '2px solid',
        '3px': '3px solid',
        '4px': '4px solid',
    },
    breakpoints,
    styles: {
        root: {
            backgroundColor: '#E5E5E5',
            color: 'gray.700',
            fontFamily: 'main',
            fontWeight: 'normal',
            lineHeight: '1.5',
        },
    },
    useCustomProperties: false,
};

export type Theme = typeof theme;

export default theme;
