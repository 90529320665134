export const loadApplication = (application) => ({
    type: 'LOAD_APPLICATON',
    application,
})

export const setIsRefinance = (status) => ({
    type: 'SET_IS_REFINANCE',
    status,
})

export const saveApplication = (partiallyCompletedApplication) => ({
    type: 'SAVING_REQUESTED'
})

export const saveInProgress = (status) => ({
    type: 'SAVING_IN_PROGRESS',
    status,
})

export const saveSuccess = (status) => ({
    type: 'SAVING_SUCCESS',
    status,
})

export const saveError = (status) => ({
    type: 'SAVING_ERROR',
    status,
})

export const stepCompleted = (stepId, values) => ({
    type: 'STEP_COMPLETED',
    stepId,
    values,
})

export const showTotalMonthlyExpenses = (show) => ({
    type: 'SHOW_TOTAL_MONTHLY_EXPENSES',
    showTotalMonthlyExpenses: show,
})

export const gotoStep = (stepId) => ({ type: 'GOTO_STEP', stepId })

export const nextStep = ({ type: 'NEXT_STEP' })

export const previousStep = ({ type: 'PREVIOUS_STEP'})

export const monthlyExpense = (name, value) => ({
    type: 'MONTHLY_EXPENSE',
    name,
    value,
})
