/** @jsx jsx */
import { useRef, useState, useEffect } from 'react';
import { jsx } from 'theme-ui';
import { motion, AnimatePresence } from 'framer-motion';

import Icon from 'components/Icon/Icon';
import Text from 'components/Text/Text';
import Emoji from './Emoji';

const Alert = ({
    key,
    show,
    type='info',
    secondsToLive=5,
    message,
    onClose,
}) => {
    const timeout = useRef();
    const [visible, setVisible] = useState(show);
    const emojis = {
        error: [
            {
                symbol: '1F62C',
                title: 'Oops, sorry!'
            },
            {
                symbol: '1F9D0',
                title: 'Oh, boy!'
            },
            {
                symbol: '1F61E',
                title: 'Yeah, nah!'
            },
            {
                symbol: '2639',
                title: 'Oh, bummer!'
            },
            {
                symbol: '1F61E',
                title: "Nope, didn't work!"
            },
        ],
        info: [
            {
                symbol: '1F44C',
                title: 'Nice one!'
            },
            {
                symbol: '1F60E',
                title: 'Oh, sweet!'
            },
            {
                symbol: '1F44D',
                title: 'Great!'
            },
            {
                symbol: '270C',
                title: 'Good one!'
            },
        ],
        submitted: ['1F91D']
    }

    const possibleEmojiList = emojis[type]

    const emoji = possibleEmojiList[Math.floor(Math.random()* possibleEmojiList.length)]

    useEffect(() => {
        if(secondsToLive) {
            timeout.current = window.setTimeout(() => {
                setVisible(false)
                onClose();
        }, secondsToLive * 1000)
        }

        return () => clearTimeout(timeout.current);
    }, [onClose, secondsToLive])

    return (
        <AnimatePresence>
        {visible && (
            <motion.div
                key={key}
                initial={{ opacity: 0, y: '-100%' }}
                animate={{ opacity: 1, y: '0'}}
                exit={{ opacity: 0, y: '100%'}}
                transition={{ ease: 'easeOut', duration: .15 }}
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    boxShadow: ['none', '0px 4px 8px rgba(0, 0, 0, 0.15)'],
                    borderRadius: [0, '8px'],
                    bg: type === 'error' ? 'red.base' : 'gray.700',
                    zIndex: 999,
                    p: '8px',
                    width: '100%',
                    maxWidth: '400px',
                }}
            >
                <div sx={{ml: '8px', mr: '16px'}}><Emoji symbol={`0x${emoji.symbol}`} size={30} /></div>
                <div sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                }}>
                    <Text size="label" color="white" sx={{
                        textTransform: ''
                    }}> {emoji.title} </Text>
                    <Text size="body.small" color="gray.200"> {message} </Text>
                </div>
                <div sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            onClick={onClose}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '5px',
                                border: 'none',
                                background: 'transparent',
                                outline: 'none',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                width: '24px',
                                height: '24px',
                                ':hover': {
                                    bg: 'gray.600',
                                }
                            }}
                        >
                            <Icon icon="close" sx={{'& path': {stroke: 'gray.200'}}} />
                        </button>
                    </div>
            </motion.div>
        )}
        </AnimatePresence>)
}

export default Alert;
