/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { SystemStyleObject, SystemCssProperties } from '@styled-system/css';

import { ColorString } from 'theme/colors';

export type SizeKeys = 'h1' | 'h2' | 'h3' | 'h4' | 'label' | 'body' | 'body.small' | 'label.small' | 'note.label' | 'note';

type AsKeys = React.ElementType;

export type TextProps = {
    as?: AsKeys;
    children?: React.ReactNode;
    color?: ColorString;
    size?: SizeKeys | undefined;
    sizes?: SizeKeys[];
    uppercase?: boolean;
} & Pick<SystemCssProperties, 'textAlign'>;

interface TagProps {
    elementName: AsKeys;
    children?: React.ReactNode;
}

type TagDetails = {
    [key in SizeKeys]: {
        size: string;
        weight: string;
    };
};

const Tag: React.FC<TagProps> = ({ elementName = 'span', children, ...props }) => {
    return React.createElement(elementName, props, children);
};

const Text: React.FC<TextProps & React.HTMLAttributes<HTMLElement>> = ({
    as = 'span',
    size,
    sizes,
    children,
    color = 'gray.900',
    uppercase,
    textAlign,
    ...props
}) => {
    const tagDetails: TagDetails = {
        h1: {
            size: 'h1',
            weight: 'bold',
        },
        h2: {
            size: 'h2',
            weight: 'bold',
        },
        h3: {
            size: 'h3',
            weight: 'bold',
        },
        h4: {
            size: 'h4',
            weight: 'bold',
        },
        label: {
            size: 'body',
            weight: 'medium',
        },
        'label.small': {
            size: 'small',
            weight: 'medium',
        },
        body: {
            size: 'body',
            weight: 'normal',
        },
        'body.small': {
            size: 'small',
            weight: 'normal',
        },
        note: {
            size: 'note',
            weight: 'normal',
        },
        'note.label': {
            size: 'note',
            weight: 'medium',
        },
    };

    let fontItems: SystemStyleObject = {};

    if (sizes) {
        fontItems = {
            fontSize: sizes,
        };
    } else {
        fontItems = {
            fontSize: size ? tagDetails[size].size : 'inherit',
            fontWeight: size ? tagDetails[size].weight : 'inherit',
        };
    }

    return (
        <Tag
            elementName={as}
            sx={{
                ...fontItems,
                textAlign,
                textTransform: uppercase ? 'uppercase' : 'none',
                color,
                lineHeight: 'base',
                m: 0,
            }}
            {...props}
        >
            {children}
        </Tag>
    );
};

export default React.memo(Text);
