/** @jsx jsx */
import { jsx } from 'theme-ui';

import styles from './ButtonLoader.module.css';

const ButtonLoader = ({ circumference = 50, radius = 20, strokeColor = 'white', strokeWidth = 5, ...restOfProps }) => {
    return (
        <div {...restOfProps}>
            <svg className={styles.loader} viewBox="25 25 50 50" >
                <circle className={styles.circle} cx={circumference} cy={circumference} r={radius} fill="none" stroke={strokeColor} stroke-width={strokeWidth} />
            </svg>
        </div>
    );
};

export default ButtonLoader;
