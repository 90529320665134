/** @jsx jsx */
import { Suspense } from 'react';
import { jsx } from 'theme-ui';
import { ThemeProvider } from 'theme-ui';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { PersistGate } from 'redux-persist/integration/react'

import Loader from 'components/Loader/Loader';
import Layout from 'views/Layout/Layout';
import theme from 'theme/theme';

import store, { history } from './store';

import App from './App';

const Root = () => (
    <Provider store={store.store}>
        <PersistGate loading={<Loader />} persistor={store.persistor}>
            <ThemeProvider theme={theme}>
                <ConnectedRouter history={history}>
                    <Layout>
                        <Suspense fallback={<Loader />}>
                            <App />
                        </Suspense>
                    </Layout>
                </ConnectedRouter>
            </ThemeProvider>
        </PersistGate>
    </Provider>
);

export default Root;
