import flatMap from 'lodash/flatMap';
import isObject from 'lodash/isObject';
import isNumber from 'lodash/isNumber';
import mapKeys from 'lodash/mapKeys';
import includes from 'lodash/includes';
import compact from 'lodash/compact';
import axios from 'axios';

import { API_BASE_URL } from 'constants/constants';

const getEncodedUrl = (url) => url && url
    .split('?')
    .map((splitUrl, i) =>
        i === 1
            ? splitUrl
                .split('&')
                .map((keyValue) =>
                    keyValue
                        .split('=')
                        .map(window.encodeURIComponent)
                        .join('='),
                )
                .join('&')
            : splitUrl,
    )
    .join('?');

const encodeFormValue = (value, key) => {
    if (value === undefined) {
        return '';
    }

    if (isObject(value)) {
        return key
            ? flatMap(value, (v, k) =>
                  isNumber(k) && !isObject(v)
                      ? encodeFormValue(v, `${key}[]`)
                      : encodeFormValue(v, `${key}[${window.encodeURIComponent(k)}]`),
              )
            : flatMap(value, encodeFormValue);
    }

    return `${key}=${window.encodeURIComponent(value)}`;
}

const getEncodedData = (data, config) => {
    const contentTypeHeader = mapKeys(config?.headers, (value, key) => key.toLowerCase())['content-type'];
    const isFormUrlEncoded = includes(contentTypeHeader, 'application/x-www-form-urlencoded');

    return isFormUrlEncoded ? compact(encodeFormValue(data)).join('&') : data;
}

const request = async (method, url, data, config) => {

    const requestUrl = `${API_BASE_URL}${url}`;

    switch(method) {
        case 'post':
        case 'put':
        case 'patch':
            return await axios[method](requestUrl, data, config)
            
        case 'get':
        case 'delete':
            return await axios[method](requestUrl, config)
        default: 
            return undefined;
    }
}

const api = {
    async ajax(method, url, data = {} , config) {
        try {
            const encodedUrl = getEncodedUrl(url);
            const encodedData = getEncodedData(data, config);
            const response = await request(method, encodedUrl, encodedData, config);

            console.log(response);

            if (!response?.data?.success && !response?.data?.error) {
                const error = new Error(
                    `${method.toUpperCase()} ${window.location.origin}${encodedUrl} ${response.status} (API)`,
                );
                error.response = response;
                error.remote = false;
                throw error;
            }

            if (!response?.status === 200 || !response.data) {
                throw new Error(
                    `${method.toUpperCase()} ${window.location.origin}${encodedUrl} ${response.status} (API not json object or array)`,
                );
            }

            return (response?.data?.data && response?.data?.data) || response?.data;

        } catch (err) {
            if (axios.isCancel(err)) {
                throw err;
            }

            if (err && err.response) {
                const error = new Error(err.message);
                error.data = err.response.data;

                if (isObject(error.data)) {
                    error.data = null;
                }

                error.status = err.response.status;
                error.remote = false;
                throw error;
            }

            if (err) {
                const error = new Error(err.message);
                error.data = null;
                error.remote = false;
                error.status = err.request?.status || 0;   
                throw error;
            }

            const error = new Error('An error has occurred');
            error.data = null;
            error.status = 0;
            error.remote = false;

            throw error;
        }
    },

    get(url, config) {
        return this.ajax('get', url, undefined, config);
    },

    post(url, data, config) {
        return this.ajax('post', url, data, config);
    },

    patch(url, data, config) {
        return this.ajax('patch', url, data, config);
    },

    put(url, data, config) {
        return this.ajax('put', url, data, config);
    },

    delete(url, config) {
        return this.ajax('delete', url, undefined, config);
    },

    createCancelToken: () => axios.CancelToken.source(),

    isCancel: (err) => axios.isCancel(err),
};

export default api;
