export const titles = [
    {
        label: 'Mr',
        value: 'Mr',
    },
    {
        label: 'Ms',
        value: 'Ms',
    },
    {
        label: 'Mrs',
        value: 'Mrs',
    },
    {
        label: 'Miss',
        value: 'Miss',
    },
    {
        label: 'Dr',
        value: 'Dr',
    },
];

export const preferredContactMethod = ['Phone', 'Email'];

export const maritalStatus = [
    {
        label: 'Single',
        value: 'single',
    },
    {
        label: 'Married',
        value: 'married',
    },
    {
        label: 'Divorced',
        value: 'divorced',
    },
    {
        label: 'De Facto',
        value: 'de-facto',
    },
    {
        label: 'Other',
        value: 'other',
    },
];

export const residentialStatus = ['Own', 'Own Home-Mortgage', 'Renting', 'Boarding', 'With parents', 'Other'];

export const licenseTypes = ['Full', 'Learner', 'Probationary'];

export const states = [
    {
        label: 'Australian Capital Territory',
        value: 'ACT',
    },
    {
        label: 'New South Wales',
        value: 'NSW',
    },
    {
        label: 'Northern Territory',
        value: 'NT',
    },
    {
        label: 'Queensland',
        value: 'QLD',
    },
    {
        label: 'South Australia',
        value: 'SA',
    },
    {
        label: 'Tasmania',
        value: 'TAS',
    },
    {
        label: 'Victoria',
        value: 'VIC',
    },
    {
        label: 'Western Australia',
        value: 'WA',
    },
];

export const allowedApplicationTypes = ['new', 'refinance'];

export const employmentTypes = ['Salaried employee', 'Self-employed', 'Retired', 'Unemployed'];

export const employmentBasis = {
    salaried: ['Full time', 'Part time', 'Casual', 'Contract'],
    nonSalaried: ['Sole trader', 'In a company', 'In a partnership', 'Contract'],
};

export const incomeFrequency = ['Weekly', 'Fortnightly', 'Monthly', 'Quarterly', 'Semiannual', 'Annually'];

export const repaymentType = [
    {
        label: 'Principal & interest',
        value: 0,
    },
    {
        label: 'Interest only',
        value: 1,
    },
];

export const homeContentType = [
    {
        label: 'Furniture',
        value: 'Furniture',
    },
    {
        label: 'Artwork',
        value: 'Artwork',
    },
    {
        label: 'Jewellery',
        value: 'Jewellery',
    },
    {
        label: 'Tools',
        value: 'Tools',
    },
    {
        label: 'Musical equipment',
        value: 'Musical equipment',
    },
    {
        label: 'Sport equipment',
        value: 'Sport equipment',
    },
    {
        label: 'Other',
        value: '-1',
    },
];

export const vehicleType = [
    'Car',
    'Motorcycle',
    'Watercraft',
    'Bicycle',
    'Light commercial',
    'Trailer',
    'Truck',
    'Bus coach or special purpose',
    'Caravan',
    'Locomotive',
    'Rail cars',
    'Other',
];

export const residencyStatus = [
    'Citizen',
    'Permanent resident',
    'Non-resident',
    'Work permit',
]

export const frequencyMap = {
    weekly: {
        label: 'Weekly',
        frequency: 4.333,
        value: 'weekly'
    },
    fortnightly: {
        label: 'Fortnightly',
        frequency: 2.17,
        value: 'fortnightly'
    },
    monthly: {
        label: 'Monthly',
        frequency: 1,
        value: 'monthly'
    },
    quarterly: {
        label: 'Quarterly',
        frequency: 0.333,
        value: 'quarterly'
    },
    semiannual: {
        label: 'Semiannual',
        frequency: 0.167,
        value: 'semiannual'
    },
    annually: {
        label: 'Annually',
        frequency: 0.083, 
        value: 'annually'
    }
}

export const monthYearRegex = /^(((0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|(02\/((19|[2-9]\d)\d{2}))|(02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/

export const fullDateRegex = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/

export const fullNameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/

export const stringWithSpacesRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/

export const APP_VERSION = 1;

export const API_BASE_URL = '/api'
