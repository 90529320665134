
export const uploadFile = (file, dispatcher) => ({
    type: 'UPLOAD_REQUESTED',
    file,
    dispatcher,
})

export const uploadInitialized = (name, file) => ({
    type: 'UPLOAD_INITIALIZED',
    name,
    file,
})

export const uploadInProgress = (fileId, percentage = 0) => ({
    type: 'UPLOAD_IN_PROGRESS',
    fileId,
    percentage,
})

export const uploadSuccess = (fileId) => ({
    type: 'UPLOAD_SUCCESS',
    fileId,
})

export const UploadFail = (fileId) => ({
    type: 'UPLOAD_FAIL',
    fileId,
})