/** @jsx jsx */
import { forwardRef } from 'react';
import { jsx } from 'theme-ui';
import { motion, AnimatePresence } from 'framer-motion';

import BaseButton from './BaseButton';
import ButtonLoader from './ButtonLoader';

const Button = forwardRef(
    (
        {
            children = null,
            color = 'primary',
            disabled = false,
            size = 'medium',
            type = 'button',
            fullWidth = false,
            shadow = false,
            loading = false,
            onClick = () => {},
            ...rest
        },
        ref,
    ) => {
        const buttonStateStyles = {
            primary: {
                bg: 'primary.base',
                color: 'white',
                hover: 'primary.dark',
                pressed: 'primary.darker',
            },
            secondary: {
                bg: 'gray.100',
                color: 'gray.800',
                hover: 'gray.200',
                pressed: 'gray.300',
            },
        };

        const buttonStateStyle = buttonStateStyles[color];

        const buttonStyle = {
            position: 'relative',
            alignItems: 'center',
            bg: buttonStateStyle.bg,
            color: buttonStateStyle.color,
            display: 'inline-flex',
            fontSize: 'body',
            fontWeight: '500',
            justifyContent: 'center',
            transition: 'all .25s cubic-bezier(0.075, 0.82, 0.165, 1)',
            width: fullWidth ? '100%' : 'auto',
            ':disabled': {
                cursor: 'not-allowed',
            },
            ':hover:not(:disabled)': {
                bg: buttonStateStyle.hover,
            },
            ':hover:not(:disabled)::after': {
                boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.2)',
            },
            ':active:not(:disabled)': {
                bg: buttonStateStyle.pressed,
                transform: shadow ? 'translateY(2px)' : 'translateY(0)',
            },
            '::after': {
                content: `' '`,
                position: 'absolute',
                display: shadow ? 'block' : 'none',
                boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.15)',
                borderRadius: '200px',
                opacity: 1,
                transition: 'all .25s cubic-bezier(0.075, 0.82, 0.165, 1)',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
            },
            ':active:not(:disabled)::after': {
                boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.2)',
            },
        };

        return (
            <BaseButton type={type} sx={buttonStyle} ref={ref} disabled={disabled} onClick={onClick} {...rest}>
                <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    {loading && (
                        <AnimatePresence>
                            <motion.div initial={{ opacity: 0, x: -20 }} animate={{ x: 0, opacity: 1 }} exit={{ x: 20, opacity: 0}}>
                                <ButtonLoader sx={{
                                    height: '24px',
                                    width: '24px',
                                    mr: '8px',
                                }} />
                            </motion.div>
                        </AnimatePresence>
                    )}
                    {children}
                </div>
            </BaseButton>
        );
    },
);

export default Button;
