const initialState = {};

export default function user(state = initialState, action) {
    switch (action.type) {
        case 'UPLOAD_INITIALIZED':
            return {
                ...state,
                [action.fileId]: {
                    percentage: 0,
                    uploadingInProgress: false,
                    success: false,
                    fail: false,
                },
            };

        case 'UPLOAD_IN_PROGRESS':
            console.log(`action:`);
            console.log(`action`);
            return {
                ...state,
                [action.fileId]: {
                   ...state[action.fileId],
                   uploadingInProgress: true,
                   percentage: action.percentage
                },
            };

        case 'UPLOAD_SUCCESS':
            return {
                ...state,
                [action.fileId]: {
                    ...state[action.fileId],
                    uploadingInProgress: false,
                    success: true
                },
            };
        
        case 'UPLOAD_FAIL':
            return {
                ...state,
                [action.fileId]: {
                    ...state[action.fileId],
                    uploadingInProgress: false,
                    fail: true
                },
            };
    
        default:
            return state;
    }
}
