/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';

const Emoji = React.memo(({ symbol, size = 20, ...props }) => (
    <span role="img" aria-label={symbol} {...props} sx={{
        fontSize: `${size}px`,
    }}>
        {String.fromCodePoint(symbol)}
    </span>
));

export default Emoji